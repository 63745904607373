import React from "react";
import {Link as LinkR} from "react-router-dom";
import styled from "styled-components";
import { LuCode2 } from "react-icons/lu";
import { FaBars } from "react-icons/fa";
import { useTheme } from "styled-components";


const Nav = styled.nav`
    background-color: ${({theme}) => theme.card_light};
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1rem;
    position: sticky;
    top: 0;
    z-index: 10;
    @media screen and (max-width: 960px){
        transition: 0.8s all ease;
    }
`;

const NavContainer = styled.div`
    display: flex;
    justify-content: space-between;
    height: 60px;
    z-index: 1;
    width: 100%;
    padding: 0 24px;
    max-width: 1100px;
`;

const NavLogo = styled(LinkR)`
    width: 100%;
    padding: 0 6px;
    display: flex;
    justify-self: flex-start;
    cursor: pointer;
    text-decoration: none;
    align-items: center;

    // Add the href attribute
    &:hover {
        text-decoration: none;
    }
`;

const MobileIcon = styled.div`
    display: none;
    @media screen and (max-width: 768px){
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(-100%,50%);
        font-size: 1.5rem;
        cursor: pointer;
        color: ${({theme}) => theme.text};
    }
`;

const NavItems = styled.ul`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 32px;
    list-style: none;

    @media screen and (max-width: 768px){
        display: none;
    }
`;
const NavLink = styled(LinkR)`
    color: ${({ theme }) => theme.text_primary};
    font-weight: 500;
    cursor: pointer;
    text-decoration: none;
    transition: all 0.2s ease-in-out;
    &:hover {
        color: ${({ theme }) => theme.primary};
    }
`;

const ButtonContainer = styled.div`
    display: flex;
    aligin-items: center;
    justify-content: end;
    width: 80%;
    padding: 0 6px;
    @media screen and (max-width: 640px){
        display: none;
    }
`;

const GitHubButton = styled.a`
    background-color: transparent;
    color : ${({theme}) => theme.primary};
    border: 1px solid ${({theme}) => theme.primary};
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px 20px;
    font-size: 1rem;
    font-weight: 500;
    cursor: pointer;
    height: 70%;
    text-decoration: none; /* Añadido para que se vea como un enlace */
    :hover{
        background-color: ${({theme}) => theme.primary};
        color: ${({theme}) => theme.white};
    }
    @media screen and (max-width: 640px){
        padding: 0.8rem;
    }
`;

export const Span = styled.span`
    padding: 0 4px;
    font-weight: bold;
    font-size: 18px;
}
`;

const MobileMenu = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap : 16px;
    position: absolute;
    top: 80px;
    right: 0;
    width: 100%;
    padding: 12px 40px 24px 40px;
    background: ${({theme}) => theme.card_light+ 99};
    transition: all 0.3s ease-in-out;
    transform: ${({opne}) => (opne ? "translateX(0)" : "translateX(100%)")};
    border-radius: 0 0 20 20px;
    box-shadow: 0px 5px 10px rgba(0,0,0,0.3);
    opacity: ${({opne}) => (opne ? "1" : "0")};
    z-index: ${({opne}) => (opne ? "1" : "-1")};
    `;

const MobileMenuLinks = styled(LinkR)`
    color: ${({theme}) => theme.text_primary};
    font-weight: 500;
    cursor: pointer;
    text-decoration: none;
    transition: all 0.2s ease-in-out;
    &:hover {
        color: ${({theme}) => theme.primary};
    }
`;


const Navbar = () => {
    const [open, setOpen] = React.useState(false); // Corrected spelling of 'open'
    const theme = useTheme();

    return (
        <Nav>
            <NavContainer>
                <NavLogo to="/">
                    <a
                        style={{
                            display: "flex",
                            alignItems: "center",
                            color: "#fff",
                            marginBottom: "20px", // Added 'px' to the marginBottom value
                            cursor: "pointer",
                        }}
                    >
                        <LuCode2 size="2rem" /> <span>Portafolio</span>
                    </a>
                </NavLogo>
                <GitHubButton
                    style={{
                        padding: "10px 16px", // Removed space between '10' and 'px'
                        background: `${theme.primary}`,
                        color: "white",
                        width: "max-content",
                    }}
                    href="https://github.com/Valde1413"
                    target="_blank"
                >
                    GitHub
                </GitHubButton>
            </NavContainer>
        </Nav>
    );
};

export default Navbar;